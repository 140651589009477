@import "./../../../styles/_lib.scss";

.ShippingMethod {
  border-bottom: 1px solid hsla(0, 0%, 67.8%, 0.3);
  padding: 20px 0;
  &__title {
    display: block;
    font-size: 13px;
    text-align: left;
    font-weight: 700;
    line-height: 18px;
    padding-left: 0;
    margin-bottom: 10px;

    color: black;
    @include mobile {
      margin-bottom: 2px;
    }
  }

  &__item {
    width: 100%;
    border-bottom: 1px solid $gray;
    padding: 10px 0;

    label {
      width: 100%;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0;

      @include mobile {
        align-items: start;
      }
    }
    span.name {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 1.3em;
      margin-bottom: 0;
      color: $black;

      // &:before {
      //   content: "";
      //   width: 30px;
      //   height: 30px;
      //   border-radius: 50%;
      //   background: $white;
      //   margin-right: 10px;
      //   display: inline-block;
      //   vertical-align: middle;
      //   border: 1px $border-color solid;
      // }
    }
    span.price {
      display: block;
      font-size: 16px;
      line-height: 1.3em;
      font-weight: bold;
      color: $black;
      margin-bottom: 0;
    }

    &:last-child {
      border-bottom: none;
    }
  }
  &__terms {
    // border-top: 1px solid rgba(173, 173, 173, 0.3);
    padding-top: 10px;
    label {
      font-size: 14px;
      line-height: 1.3em;
      margin-bottom: 0;
      color: #000000;
      font-weight: 500;
    }
  }
}

.is--invalid.invalid-feedback {
  display: block;
}
