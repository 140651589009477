@import "../../styles/lib";

.Sidebar {
  width: 100%;
  height: 100%;
  position: relative;
  @include desktop {
    height: auto;
    min-height: 100%;
  }
  &__close {
    display: none;
    position: absolute;
    top: 15px;
    right: 19px;
    z-index: 10;

    &:hover {
      svg {
        fill: #2b2b2b;
      }
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    position: sticky;
    top: 170px;
    transition: all 0.1s;
    @include desktop {
      margin-bottom: 20px;
    }

    @include mobile {
      overflow: auto;
    }
  }

  @include mobile {
    position: fixed;
    top: 0;
    width: 100%;
    background: #fff;
    z-index: 102;
    height: 100%;
    padding: 30px 15px;
    transform: translateX(-90%);
    transition: all ease-in-out 400ms;
    box-shadow: 0 0 20px 0 rgba(85, 85, 85, 0.31);

    left: -90%;

    &--opened {
      transform: translateX(0);
      transition: all ease 400ms;
      left: 0;
    }

    &__close {
      display: block;
    }

    &__content {
      margin-top: 20px;
    }
  }
}
