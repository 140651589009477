@import "./../../styles/_lib.scss";

.Checkout {
  margin-top: 50px;

  &__address-actions {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    @include mobile {
      flex-wrap: wrap;
    }
    @include tablet {
      flex-wrap: wrap;
    }

    &__title {
      color: $black;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 0;
      line-height: 18px;
      margin-right: 34px;

      text-transform: uppercase;
      &--delivery {
        @include mobile {
          display: none !important;
        }
      }

      @include mobile {
        width: 100%;
        display: block;
        margin-bottom: 15px;
      }
      @include tablet {
        width: 100%;
        display: block;
        margin-bottom: 15px;
      }
    }

    &__delivery {
      height: 30px;

      align-items: center;
      font-size: 16px;
      line-height: 1.3em;
      color: $black;
      margin-bottom: 0;

      background-color: $white;
      &.is--active {
        &:before {
          background-repeat: no-repeat;
          background-position: center center;
          background-image: url("./../../assets/icons/check.svg");
        }
      }
      &:before {
        content: "";
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: $white;
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
        border: 1px $border-color solid;
      }

      @include mobile {
        width: auto;
        flex-grow: 0;
        margin-right: 10px;
        padding: 0 10px 0 30px;
      }
      @include tablet {
        width: auto;
        flex-grow: 0;
        margin-right: 10px;
        padding: 0 10px 0 25px;
      }
    }

    &__new-delivery {
      color: $black;
      display: block;
      font-size: 12px;
      padding-left: 17px;

      @include mobile {
        width: 45%;
        flex-grow: 0;
        font-size: 11px;
        padding-left: 10px;
      }
      @include tablet {
        flex-grow: 0;
      }
    }
  }

  .agreement {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 !important;

    label {
      font-size: 12px;
      color: lighten($black, 46.5%) !important;

      @include mobile {
        margin-left: 0;
      }

      a {
        color: $black;
        text-decoration: underline;
      }

      input {
        margin-top: 3px;
      }
    }
  }
}
