@import "../../../styles/lib";

.InnerHeader {
  @include tablet {
    padding: 0 15px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-left {
      display: flex;
      justify-content: center;
      padding: 10px 0;
    }
		&-middle{
			display: flex;
			width: 100%;
			justify-content: center;
		}
    &-right {
      display: flex;
      padding: 0 5px;
      justify-content: space-between;
      flex-direction: column;
      z-index: 1;
      // border-left: 1px solid #ebebeb;
      &--search {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
    }
    @include tablet {
      justify-content: space-between;
      &-left {
        width: unset;
        padding: 10px 0;
      }
      &-right {
        border: unset;
        width: unset;
        padding: 0 5px;

        &--search {
          padding: 0;
        }
      }
      .Logo {
        a {
          margin-left: 10px;
          svg {
            width: 120px;
            height: 40px;
          }
        }
      }
      // .UserNavigation {
      //   width: 130px;
      // }
    }
    @include mobile {
      justify-content: space-between;
      &-left {
        width: unset;
        padding: 10px 0;
      }
      &-right {
        border: unset;
        width: unset;
        padding: 0 5px;
        &--search {
          padding: 0;
        }
      }
      .Logo {
        a {
          margin-left: 10px;
          svg {
            width: 120px;
            height: 40px;
          }
        }
      }
      // .UserNavigation {
      //   width: 130px;
      // }
    }
  }

  // .Logo {
  //   width: 150px;
  //   margin-right: 40px;
  //   @include mobile {
  //     width: 200px;
  //     margin-right: 0;
  //   }
  // }
}
