@import "./src/styles/lib";

.Login {
  display: flex;
  &-moto {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    height: 100%;

    @include mobile {
      justify-content: center;
      align-items: center;
    }
    img {
      width: 150px;
      margin-bottom: 10px;
    }
    h2 {
      font-family: "Bebas Neue", cursive;
      font-size: 45px;
      color: #2d2d2d;
    }
  }
  &__wrapper {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;

    &-text {
      display: inline-block;
      margin: 0;
    }

    button {
      margin-top: 30px;
      width: 100%;
    }
    h4 {
      margin-bottom: 30px;
      font-weight: 600;
    }
  }

  &__links {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  .form-control {
    padding: 0 15px !important;
    border-radius: 0 !important;
  }
  &__border {
    border-bottom: 1px solid #dadde1;
    width: 100%;
    margin-top: 30px;
  }
  &__register {
    width: auto !important;
  }
}
