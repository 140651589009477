@import "../../../styles/lib";

.HomeProducts{
	margin-top: 20px;
	&__products{
		grid-template-columns: repeat(4, 4fr);
		@include tablet{
			grid-template-columns: repeat(2, 4fr);
		}
	}

}