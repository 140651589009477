@import "../../../styles/lib";

.CheckoutForm {
  margin-bottom: 40px;
  @include tablet {
    &--footer {
      margin-top: 20px;
    }
    .CartTotal {
      align-items: flex-start;
      margin-top: 20px;
      button {
        margin-left: unset;
      }
    }
  }

  .form-group {
    label {
      color: black !important;
    }
  }
  &__details {
    padding: 20px;
    background-color: #fbfbfb;
    border: 10px solid #ebeaea;
		border-radius: 20px;
  }
  &--footer {
    margin-top: 20px;
  }
}
