@import "./../../styles/_lib.scss";

.Reviews{
	display: grid;
	grid-template-columns: 3fr 1fr;
	grid-gap: 30px;
	margin-top: 15px;
	justify-content: center;
	align-items: flex-start;

	@include tablet{
		grid-template-columns: 1fr;
	}
	&__empty{
		padding: 15px;
    color: #77001f;
    background: #fdc8cc;
		border-radius: 20px;
		width: 100%;
		> p{
			margin-bottom: 0;
		}
	}
	&__right { 
		display: flex;
		> button {
			width: 100%;
		}
	}
	&__left { 
		display: flex;
		&__comments{
			width: 100%;
			&--comment{
				display: flex;
				justify-content: space-between;
				padding: 15px;
				color: black;
				background: #ebebeb;
				border-radius: 20px;
				width: 100%;
				margin-bottom: 10px;
				 p{
					margin-bottom: 0;
					font-size: .9375rem;
				}
				 label{
					font-weight: 600;
					line-height: 1.2308;
				}
				&-right{
					display: flex;
					align-items: flex-end;
					justify-content: space-between;
					flex-direction: column;
					> label {
						font-size: 12px;
						font-weight: normal;
						margin-bottom: 0;
					}
				}
			}
		}
	}
}