.ProductSimpleAttributes{
	margin-top: 40px;
	&__features{
		font-size: 20px;
    font-weight: 700;
		border-bottom: 1px solid #ebebeb;
		display: block;
		padding-bottom: 5px;
		> span{
			margin-right: 15px;
			text-transform: uppercase;
			border-bottom: 1px solid transparent;
			padding-bottom: 7.8px;
			&:hover{
				cursor: pointer;
			}
		}
		&__attr-name{
			width: 280px;
		}
	}
}