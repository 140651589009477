@import "./src/styles/lib";

.HeadingTitle {
  h1 {
    color: $black;
    font-size: 32px;
    position: relative;
    margin-bottom: 44px;
    display: inline-block;

    @include tablet {
      font-size: 22px;
    }

    @include mobile {
      font-size: 22px;
      margin-bottom: 22px;
      line-height: 35px;

      &::after {
        top: 40%;
      }
    }
  }

  &__icon {
    margin-left: 10px;
    min-width: 20px;
    min-height: 20px;
  }

  @include mobile {
    text-align: center;
  }
}
