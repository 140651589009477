@import "./src/styles/lib";

.Profile {
  &.mt-heading {
    margin-top: 55px !important;
  }

  .HomeTabs__tab--active a {
    color: #ffffff !important;
  }
}
