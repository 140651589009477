@import "src/styles/lib";

.CartSidebar {
  width: 400px;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  visibility: hidden;
  background: #eee;
  box-shadow: 0 0 49px 3px #08080847;
  transition: 0.2s ease-in-out;
  transform: translateX(100%);
  z-index: 100;
  overflow: hidden;

  @include mobile {
    width: 90%;
  }

  &--opened {
    visibility: visible;
    transform: translateX(0);
  }

  &--updating {
    background: #ffffff;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-empty {
    width: 100%;
    height: 100%;
    display: flex;
    // align-items: center;
    justify-content: center;
    padding-top: 15px;
  }

  &-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #dfdddd;
    p {
      margin: 0;
      font-size: 20px;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &-body {
    height: calc(100% - 261px);
    overflow: auto;
    padding: 20px;
  }

  &-list {
    &-item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 20px;

      background: #ffff;
      padding: 10px;
       border-radius: 20px;

      &-image {
        width: 100px;
        height: 100px;
        border: 1px solid #eee;
        padding: 2px;
				border-radius: 20px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
					border-radius: 20px;
        }
      }

      &-info {
        padding-left: 20px;
        h4 {
          font-size: 18px;
        }
        h5 {
          font-size: 14px;
          text-align: left;
          font-weight: 700;
          line-height: 1.3em;
          padding-left: 0;

          color: #bbbbbb;
          margin-right: 10px;
          margin-bottom: 0;
        }
        .ProductPrices {
          h4 {
            margin-bottom: 0;
            font-size: 14px;
          }
        }
      }

      &-remove {
        margin-left: auto;
        background: none;
        border: none;
        outline: none;
      }
    }
  }

  &-footer {
    background: #fff;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 20px;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;

    a {
       border-radius: 20px;
      color: #fff !important;
      font-size: 20px;
      background: $primary;
      padding: 15px;
      text-align: center;

      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background: darken($primary, 10%);
      }
    }
  }

  &-total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    span {
      color: #000;
      font-weight: 600;
      font-size: 18px;
    }
    strong {
      font-size: 22px;
    }
  }
}
