@import "./../../styles/_lib.scss";

.GoogleMaps {
  display: flex;
  width: 100%;
  min-height: 400px;
  height: 100%;
  @include mobile {
    flex-wrap: wrap;
    height: 300px;
  }
  @include tablet {
    margin-top: 30px;
  }
}
