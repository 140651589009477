@import "src/styles/lib";

.ProfileSidebar {
  .HomeTabs__tabs {
    justify-content: center;
  }
  @media (max-width: 500px) {
    .HomeTabs__tabs {
      padding-left: 200px;
    }
  }
  a {
    transition: none !important;
  }

  .HomeTabs {
    width: 100%;
    margin-top: 100px;
    &__tabs {
      display: flex;
      margin-bottom: 30px !important;
      overflow: hidden;
      overflow-x: auto;

      a {
        white-space: nowrap;
      }
    }
    &__tab {
      margin: 0 15px;
      font-size: 20px;
      font-weight: 800;
      cursor: pointer;
      padding: 10px;
      border-radius: 20px;
      &:hover {
        opacity: 0.8;
      }
    }
    &__tab--active {
      background-color: $primary;
      color: #fff;
      border-bottom: 2px solid $purple;
    }
  }
}
