.SingleProduct__content__buttons {
  .Quantity {
    margin: 0;
  }
  &--buttons {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }
  .btn--addtocart {
    margin-left: 0 !important;
  }
  .btn--wishlist {
    margin-top: 10px !important;
    background-color: white !important;
    color: black !important;
    border-color: black !important;
    border: 1px solid black;
  }
}
