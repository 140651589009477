@import "./../../styles/_lib.scss";

.Wishlist {
  margin: 55px 0;

  .WishlistButton {
    @include tablet {
      width: 40px !important;
      height: 40px !important;
    }
  }

  &__list {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(4, 4fr);
    @include tablet {
      grid-gap: 30px;
      grid-template-columns: repeat(3, 4fr);
    }
    @include mobile {
      grid-gap: 15px;
      grid-template-columns: repeat(2, 4fr);
    }
  }
}
