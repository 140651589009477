@import "./../../../styles/_lib.scss";

.BrandsIcons {
	margin-top: 100px;
	
  &__list {
    display: grid;
    grid-gap: 70px;
    grid-template-columns: repeat(6, 1fr);
		user-select: none;

    @include tablet {
      grid-gap: 70px;
      grid-template-columns: repeat(4, 1fr);
    }
    @include mobile {
      grid-gap: 30px;
      grid-template-columns: repeat(3, 1fr);
    }

    &__item {
      width: 100%;
      height: 60px;
      position: relative;
      list-style-type: none;
      @include mobile {
        // background: #e5e5e5;
        // border-radius: 10px;
        // padding: 31px 20px;
        // height: unset;
        // &:hover {
        //   cursor: pointer;
        //   img {
        //     filter: none;
        //     opacity: 1;
        //   }
        // }
      }
      a {
        width: 100%;
        height: 100%;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        &:hover {
          img {
            filter: none;
            opacity: 1;
          }
        }
        h4 {
          font-size: 16px;
          text-align: center;
        }
        img {
          filter: grayscale(100%);
          max-width: 100%;
          max-height: 100%;
          position: absolute;
          transition: all 0.3s ease-in-out;
          opacity: 0.2;

          @include mobile {
            position: initial;
            filter: none;
            opacity: unset;
          }
        }
      }

      &__name {
        font-weight: 600;
        font-size: 16px;
        margin: 0;
        text-transform: uppercase;
      }
    }
  }
}
