@import "src/styles/lib";
.Header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
  &__wrapper {
    z-index: 5;
    position: relative;
  }

  &-search-mobile {
    position: relative;
    display: none;
    background-color: $secondary;
    padding: 0 15px 15px 15px;
    width: 100%;
    z-index: 10;
    @include tablet {
      display: flex !important;
    }
    form {
      width: 100%;
    }

    .Search__results {
      width: calc(100% - 30px);
      @include tablet {
        width: 100%;
      }
    }
    @include tablet {
      padding: unset;
      background-color: unset;
    }
    @include mobile {
      padding: unset;
      background-color: unset;
    }
  }
  @include tablet {
    padding: unset;
  }
}
