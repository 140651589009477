@import "src/styles/lib";

.SingleProductDetails {
  width: 100%;
  margin-top: 25px;
  padding-top: 20px;
  border-top: 1px solid $gray;
  @include mobile {
    margin-top: 15px;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;

    @include tablet {
      display: block;
    }
    @include mobile {
      display: block;
    }
    li {
      margin-right: 4px;
      margin-bottom: 4px;
    }
    p {
      margin-bottom: 0;
    }
    span {
      color: black;
      font-weight: bold;
      margin-right: 4px;
    }
  }
  &__transportInfo {
    display: flex;
    border-top: 1px #dddddd solid;
    margin-top: 20px;
    padding-top: 20px;
    &__content {
      position: relative;
      display: flex;
      align-items: center;
      &:hover {
        cursor: pointer;
        .TransportInfoDropDown {
          opacity: 1;
          visibility: visible;
          left: 100%;
          cursor: default;

          @include mobile {
            left: 0;
            margin-left: 0px;
          }
          @include small-desktop {
            left: 0;
            margin-left: 0px;
          }
        }
      }
    }
    span {
      color: #b9b9b9;
      font-size: 14px;
      line-height: 21px;
      font-weight: 500;
      padding: 0px 5px;
    }
  }
  &__terms {
    &--title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: all 200ms;
      svg {
        width: 18px;
        height: 18px;
      }
      h2 {
        color: #111;
        font-weight: 500;
        font-size: 18px;
        line-height: 1.2;
        margin-bottom: 0;
      }
      &:hover {
        cursor: pointer;
      }
      &[data-opened="true"] {
        svg {
          transform: rotate(90deg);
          transition: all 200ms;
        }
      }
    }
    &_content {
      margin-top: 15px;
      p {
        margin-bottom: 4px;
      }
    }
  }
}

.TransportInfoDropDown {
  width: 345px;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s ease-in-out;
  left: calc(100% + 10px);
  background-color: white;
  border: 1px #dddddd solid;
  padding: 16px 11px;
  align-items: center;
  margin-left: 22px;

  @include mobile {
    left: 0;
    margin-left: 0px;
    width: 290px;
    z-index: 5;
    top: 40px;
  }
  @include small-desktop {
    left: 0;
    margin-left: 0px;
    width: 345px;
    z-index: 5;
    top: 40px;
  }

  p {
    margin-bottom: 0;
    font-size: 12px;
    line-height: 18px;
  }
  &::before {
    content: "";
    left: -8px;
    top: 0;
    bottom: 0;
    width: 15px;
    height: 15px;
    margin: auto 0;
    text-align: center;
    position: absolute;
    background: white;
    transform: rotate(135deg);
    -o-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    border-right: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;

    @include mobile {
      margin: 0 auto;
      left: -33px;
      right: 0;
      top: -8px;
      bottom: 0;
      transform: rotate(225deg);
      -o-transform: rotate(225deg);
      -ms-transform: rotate(225deg);
      -moz-transform: rotate(225deg);
      -webkit-transform: rotate(225deg);
    }

    @include small-desktop {
      margin: 0 auto;
      left: -89px;
      right: 0;
      top: -8px;
      bottom: 0;
      transform: rotate(225deg);
      -o-transform: rotate(225deg);
      -ms-transform: rotate(225deg);
      -moz-transform: rotate(225deg);
      -webkit-transform: rotate(225deg);
    }
  }
}
