@import "./../../styles/_lib.scss";

.Contact {
  margin-top: 50px;
  &Form {
    margin-top: 100px;
  }

  textarea {
    height: 131px !important;
    resize: none;
  }

  &__btn_submit {
    width: 240px !important;
    max-width: 240px !important;
    @include mobile {
      width: 100% !important;
      max-width: unset !important;
    }
  }

  &__address {
    color: $black;
    font-size: 14px;
    // margin-top: 20px;
    line-height: 21px;
    @include mobile {
      margin-top: 30px;
    }

    a {
      &:hover {
        color: $primary;
      }
    }
    > span {
      font-size: 12px;
      color: #626262;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    h5 {
      margin-top: 10px;
      color: black;
      font-weight: 600;
      margin: 30px 0 25px 0;
    }
    &_infos {
      span {
        display: flex;
        flex-direction: column;
        color: #626262;
        font-size: 14px;
        margin-bottom: 14px;
      }
      &--email,
      &--map,
      &--phone,
      &--clock {
        display: flex;
        align-items: center;
        svg {
          width: 14px;
          height: 13px;
        }
      }
    }
  }
}
