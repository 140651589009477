@import "./../../styles/_lib.scss";

.ListOfProducts {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(3, 4fr);

  &--empty {
    grid-template-columns: repeat(1, 4fr) !important;
  }

  @include mobile {
    grid-gap: 15px;
    grid-template-columns: repeat(2, 4fr);
  }
  @include tablet {
    grid-template-columns: repeat(2, 4fr);
  }
	&__one{
			grid-template-columns: repeat(1,1fr);
			grid-gap: 50px;

			.Product{
				flex-direction: row;
				&__image_and_slider{
					display: flex;
					flex-direction: column;
					margin-right: 30px;
					width: 40%;
					min-width: 40%;
					max-width: 40%;
					@include tablet{
						margin-right: 15px;
					}
				}
				&__content button.btn--addtocart {
					flex: unset;
				}
			}
	}
}

.Product {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: hidden;

  &:first-child {
    &__image {
      &__holder {
        max-height: initial;
				border-radius: 20px;
      }
    }
  }

  &:hover {
    .Product__image__holder {
      border-color: $primary;
      border-radius: 20px;
    }
    img {
      transform: scale(1.1);
    }
  }

  &__image {
    width: 100%;
    display: block;
    position: relative;
     border-radius: 20px;
    border: 1px solid #ebebeb;
    // max-width: 390px;
    &__holder {
      border: 1px solid transparent;
      height: 280px;
      width: 100%;
      // max-width: 390px;
      display: block;
      border-radius: 9px;
      overflow: hidden;
      a {
        width: 100%;
        height: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        transition: 0.5s;
				border-radius: 18px;
        // max-width: 390px;
      }

      @include tablet {
        max-height: 271px;
      }

      @include mobile {
        max-height: 215px;
      }
    }
  }
  &__images-slider {
    display: flex;
    margin-top: 10px;

    .active {
      border-color: $primary;
    }

    &-img {
      border: 1px solid #ebebeb;
      margin-right: 5px;
      width: 52px;
      height: 52px;
			border-radius: 10px;

      > img {
        width: 50px !important;
        height: 50px !important;
				border-radius: 20px;
        min-height: unset !important;
        transform: unset !important;
        cursor: pointer;
      }
    }
  }

  &__brand-name {
    display: block;
    padding: 0;
    margin-bottom: 7px;
    span {
      font-size: 12px;
      font-weight: 300;
      line-height: 1.3em;
      background: $white;
      color: lighten($black, 65%);
      text-transform: capitalize;
    }
  }

  .WishListButton {
    position: absolute;
    right: 10px;
    top: 10px;
    margin-top: 0;
    margin-right: 0;
    padding: 5px 5px !important;
    width: 50px !important;
    min-width: 50px !important;

    @include mobile {
      bottom: 5px;
      right: 5px;
      width: 40px !important;
      height: 40px !important;
      min-width: 40px !important;
    }

    button {
      background-color: $gray_light;
    }
  }

  &__content {
    display: flex;
    padding: 10px 0 0;
    flex-wrap: wrap;
    text-align: left;
		flex-direction: column;

		button.btn--addtocart {
			text-align: center;
			height: 47px;
			color: $white;
			margin: 10px 0;
			display: block;
			// flex: 1 1 auto;
			font-size: 16px;
			padding: 0 10px;
			max-width: 200px;
			min-width: 200px;
			font-weight: 500;
			line-height: 40px;
			transition: 300ms ease;
			border-radius: 20px;
			background-color: $primary;

			&:hover {
				background-color: darken($primary, 4%);
			}
			@include tablet {
				padding: 0 10px;
				max-width: none;
				// margin-right: 15px;
				min-width: unset;
				width: fit-content;
			}
			@include mobile {
				padding: 0 10px;
				max-width: none;
				// margin-right: 15px;
				min-width: unset;
				width: fit-content;
			}

			&:disabled {
				background-color: #d8d8d8;
				border-color: #d9d9d9;
				color: white;
			}
		}

    a {
      width: 100%;
      display: block;
      @include mobile {
        width: 100%;
      }
    }

    &__title {
      color: $black;
      display: block;
      font-size: 15px;
      line-height: 1.3em;
      font-weight: 400;
      margin-bottom: 5px;
    }

    .ProductPrices {
      width: 100%;
    }
  }

  &--big {
    grid-row: span 2;
    grid-column: span 2;

    .Product__image {
      flex: 1 1 auto;
      max-height: 509px;

      &__holder {
        max-height: 509px;
        @include mobile {
          max-height: 280px;
        }
        @include tablet {
          max-height: 380px;
        }

        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .Product__content {
      a {
        width: 90%;
      }
    }

    @include mobile {
      min-height: 400px;
    }
  }
  &__info {
    display: flex;
    align-items: center;
    justify-self: center;
    position: absolute;
    bottom: 10px;
		left: 5px;
    &-limited {
      background-color: #ebebeb !important;
      color: black !important;
			border-radius: 20px;
    }
  }

  &__out-of-stock {
    display: flex;
    align-items: center;
    justify-self: center;
    font-size: 11px;
    line-height: 14px;
    color: white;
    font-weight: 700;
    background-color: black;
    padding: 5px 7px;
    bottom: 10px;
    margin-right: 5px;
		border-radius: 20px;

    @include tablet {
      font-size: 10px;
      line-height: 15px;
      font-weight: 600;
      padding: 2px 5px;
    }

    @include mobile {
      font-size: 10px;
      line-height: 15px;
      font-weight: 600;
      padding: 2px 5px;
    }
  }

  &__discount {
    display: flex;
    align-items: center;
    justify-self: center;
    font-size: 11px;
    line-height: 14px;
    color: white;
    font-weight: 700;
    background-color: #eb0037;
    padding: 5px 7px;
    bottom: 10px;
    margin-right: 5px;
		border-radius: 20px;

    @include tablet {
      font-size: 10px;
      line-height: 15px;
      font-weight: 600;
      padding: 2px 5px;
    }

    @include mobile {
      font-size: 10px;
      line-height: 15px;
      font-weight: 600;
      padding: 2px 5px;
    }
  }
}

.ListOfProductsLoader {
  width: 100%;
  height: auto;
}
