@import "src/styles/lib";

.Home {
  margin-top: 10px;
  .container {
    @include mobile {
      padding: 0;
    }
  }
}
