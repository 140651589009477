.FeedbackModal {
  margin-top: 16px;
  &__rate {
    display: flex;
    flex-direction: column;
    margin: 0 0 16px 0;

    label {
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
    }
    span {
      width: 100%;
      margin-top: 0.25 rem;
      font-size: 80%;
      color: #fe0001;
    }
  }
  &__select {
    height: 44px !important;
  }
  textarea.form-control {
    height: 150px !important;
		border-radius: 20px !important;
  }
}
